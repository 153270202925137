import React, { useEffect, useMemo, useState } from 'react'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import {
    addPickupCoords,
    addPickupFormattedAddress,
    addPickupLocation,
    addReturnCoords,
    addReturnFormattedAddress,
    addReturnLocation,
    clearPickup,
    clearReturn,
} from '../../actions/searchActions'

import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import { prefilledDestinationsVar } from '../../cache/cache'
import { getBounds } from '../../utils/atoms'
import { useTranslation } from 'next-i18next'

function GooglePlaceInput({
    addPickupLocation,
    addPickupCoords,
    addPickupFormattedAddress,
    clearPickup,
    addReturnLocation,
    addReturnCoords,
    addReturnFormattedAddress,
    clearReturn,
    locationStatus,
    pickupLocation,
    returnLocation,
}) {
    const { t, ready } = useTranslation()
    const preFilledDestinations = prefilledDestinationsVar()
    const nextRouter = useRouter()
    const preSelectedLocation = useRouter()?.query.location?.toLowerCase() || ''

    const preSelectedPickupLocation = useMemo(
        () => preFilledDestinations[preSelectedLocation],
        [preSelectedLocation]
    )

    const bounds = getBounds()

    useEffect(() => {
        if (bounds) {
            // eslint-disable-next-line no-undef
            const mapBounds = new google.maps.LatLngBounds(
                // eslint-disable-next-line no-undef
                new google.maps.LatLng(bounds.southwest.lat, bounds.southwest.lng),
                // eslint-disable-next-line no-undef
                new google.maps.LatLng(bounds.northeast.lat, bounds.northeast.lng)
            )
            setMapBounds(mapBounds)
        }
    }, [bounds])

    useEffect(() => {
        if (preSelectedPickupLocation && preSelectedPickupLocation?.description != null) {
            setText(
                preSelectedPickupLocation.description.length > 40
                    ? `${preSelectedPickupLocation.description.substr(0, 40)}...`
                    : preSelectedPickupLocation.description
            )
            onSelect(preSelectedPickupLocation)

            if (preSelectedLocation && nextRouter) {
                nextRouter.replace({ query: '' }).then(
                    () => {
                        console.debug('GooglePlacesInput: cleared' + ' query')
                    },
                    (err) => {
                        console.error('GooglePlacesInput: error clearing query', err)
                    }
                )
            }
            return
        }
        if (locationStatus === 'pickup') {
            if (pickupLocation) {
                setText(
                    pickupLocation.description.length > 40
                        ? `${pickupLocation.description.substr(0, 40)}...`
                        : pickupLocation.description
                )
                const location = {
                    id: pickupLocation.id,
                    googlePlaceId: pickupLocation.googlePlaceId,
                    description: pickupLocation.description,
                }
                setLocation({
                    location,
                })
            }
        } else {
            if (returnLocation) {
                setText(
                    returnLocation.description.length > 40
                        ? `${returnLocation.description.substr(0, 40)}...`
                        : returnLocation.description
                )
                const location = {
                    id: returnLocation.id,
                    googlePlaceId: returnLocation.googlePlaceId,
                    description: returnLocation.description,
                }
                setLocation({
                    location,
                })
            }
        }
    }, [])

    const [, setLocation] = useState(null)
    const [mapBounds, setMapBounds] = useState(null)

    const [, setCoords] = useState(null)
    const [, setFormatted_address] = useState(null)

    const [text, setText] = useState('')
    const onSelect = (loc) => {
    // console.debug({ loc })

        const res = loc.value

        setText(res.description.length > 40 ? `${res.description.substr(0, 40)}...` : res.description)
        const placeID = res.place_id
        let coords = null
        const location = {
            id: res.id,
            googlePlaceId: res.place_id,
            description: res.description,
        }
        setLocation({
            location,
        })

        if (locationStatus === 'pickup') {
            addPickupLocation(location)
        } else {
            addReturnLocation(location)
        }
        geocodeByPlaceId(placeID)
            .then((res) => {
                if (res && Array.isArray(res) && res.length > 0) {
                    coords = {
                        lat: res[0].geometry.location.lat(), //FIXME this indeed needs to be ()
                        lng: res[0].geometry.location.lng(),
                    }
                    setCoords(coords)
                    setFormatted_address(res[0].formatted_address)
                    if (locationStatus === 'pickup') {
                        addPickupCoords(coords)
                        addPickupFormattedAddress({ formatted_address: res[0].formatted_address })
                    } else {
                        addReturnCoords(coords)
                        addReturnFormattedAddress({ formatted_address: res[0].formatted_address })
                    }
                }
            })
            .catch((error) => {
                console.error('GooglePlacesInput', error)
            })
    }

    const clearSuggestion = () => {
        setText('')
        setLocation(null)
        setFormatted_address(null)
        if (locationStatus === 'pickup') {
            clearPickup()
        } else {
            clearReturn()
        }
    }

    return (
        <>
            {ready && (
                <div className="places_container">
                    <label
                        className="d-block text-left"
                        htmlFor={`${
                            locationStatus === 'pickup' ? 'left' : 'right'
                        }-react-google-places-autocomplete-input`}
                    >
                        {locationStatus === 'pickup'
                            ? t('Type pick-up location or address')
                            : t('Type drop-off location or address')}
                    </label>

                    <GooglePlacesAutocomplete
                        selectProps={{
                            placeholder: t('Type your location, e.g. airport'),
                            onChange: (res) => onSelect(res),
                            classNamePrefix: 'dropdown',
                            noOptionsMessage: () => null,
                        }}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ['gr'],
                            },
                            locationRestriction: mapBounds,
                        }}
                        debounce={100}
                        id={locationStatus === 'pickup' ? 'pickup_gpc' : 'return_gpc'}
                        placeholder={
                            locationStatus === 'pickup'
                                ? t('Type pick-up location or address')
                                : t('Type drop-off location or address')
                        }
                        renderInput={(props) => {
                            return (
                                <div className="google_input">
                                    <input
                                        {...props}
                                        value={text}
                                        onChange={(ev) => {
                                            setText(ev.target.value)
                                            props.onChange(ev)
                                        }}
                                    />
                                    <div className="clear" onClick={clearSuggestion}>
                                        <i className="fa fa-trash"></i>
                                    </div>
                                </div>
                            )
                        }}
                    />
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    location: state.searchReducer.location,
})

const mapDispatchToProps = (dispatch) => {
    return {
        addPickupLocation: (payload) => dispatch(addPickupLocation(payload)),
        addPickupCoords: (payload) => dispatch(addPickupCoords(payload)),
        clearPickup: () => dispatch(clearPickup()),
        addPickupFormattedAddress: (payload) => dispatch(addPickupFormattedAddress(payload)),
        addReturnLocation: (payload) => dispatch(addReturnLocation(payload)),
        addReturnCoords: (payload) => dispatch(addReturnCoords(payload)),
        addReturnFormattedAddress: (payload) => dispatch(addReturnFormattedAddress(payload)),
        clearReturn: () => dispatch(clearReturn()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GooglePlaceInput)
