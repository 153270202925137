import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { FuelTypes } from '../../types'
import { useTranslation } from 'next-i18next'
const FormFuel = ({ setFuel }) => {
    const { t, i18n } = useTranslation()
    const [fuelType, setFuelType] = useState(t('Fuel Type'))

    const onChange = (obj) => {
        if (obj) {
            setFuel(obj?.value)
            setFuelType(obj.label)
        } else {
            setFuel('')
        }
    }
    useEffect(() => {
        setFuelType(t('Fuel Type'))
    }, [i18n.language, t])
    const fuelOptions = [
        { value: 'benzine', label: t(FuelTypes.BENZINE) },
        { value: 'diesel', label: t(FuelTypes.DIESEL) },
        { value: 'LPG', label: t(FuelTypes.LPG) },
        { value: 'gas', label: t(FuelTypes.GAS) },
        { value: 'electric', label: t(FuelTypes.ELECTRIC) },
        { value: 'hybrid', label: t(FuelTypes.HYBRID) },
    ]

    return (
        <div className="categories_container">
            <Select
                value={fuelType}
                onChange={onChange}
                options={fuelOptions}
                className=""
                placeholder={fuelType}
            />
        </div>
    )
}

export default FormFuel
