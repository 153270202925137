import React, { useEffect, useState } from 'react'
import { TransmissionTypes } from '../../types'
import Select from 'react-select'
import { useTranslation } from 'next-i18next'
const FormTransmission = ({ setTransmission }) => {
    const { t, i18n } = useTranslation()
    const [tranmissionType, setTransmissionType] = useState(t('Transmission Type'))

    const onChange = (obj) => {
        if (obj) {
            setTransmission(obj?.value)
            setTransmissionType(obj.label)
        } else {
            setTransmission('')
        }
    }

    useEffect(() => {
        setTransmissionType(t('Transmission Type'))
    }, [i18n.language, t])
    const transmissionOptions = [
        { value: 'manual', label: t(TransmissionTypes.MANUAL) },
        { value: 'auto', label: t(TransmissionTypes.AUTO) },
    ]

    return (
        <div className="categories_container">
            <Select
                value={tranmissionType}
                onChange={onChange}
                options={transmissionOptions}
                className=""
                placeholder={tranmissionType}
            />
        </div>
    )
}

export default FormTransmission
