export type Page =
  | 'mainPage'
  | 'partnerPage'
  | 'faq'
  | 'aboutUs'
  | 'termsOfUsage'
  | 'privacyPolicy'
  | 'blog'
  | 'motos'
  | 'atvs'
  | 'cars'

export enum FuelTypes {
  BENZINE = 'Benzine',
  DIESEL = 'Diesel',
  LPG = 'LPG',
  GAS = 'Gas',
  ELECTRIC = 'Electric',
  HYBRID = 'Hybrid',
}

export enum TransmissionTypes {
  MANUAL = 'Manual',
  AUTO = 'Auto',
}

export enum VehicleTypes {
  MOTO = 'moto',
  ATV = 'atv',
  CAR = 'car',
}

export enum ColorTypes {
  PURPLE = 'bg-c679e3',
  ORANGE = 'bg-eb6b3d',
  GREEN = 'bg-cdf1d8',
  PINK = 'bg-f78acb',
  DEFAULT = '',
}

interface textType {
  text: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  html: any //FIXME: any
}
export interface LandingPageContent {
  id: string
  altText: string
  attribution: string
  header2: string
  headline: string
  title: string
  text: textType[]
  text_de: textType[]
  text_fr: textType[]
  text_it: textType[]
  image: ImageType
}
export interface LandingPageLayoutProps {
  place: string
  content: LandingPageContent
}

export interface MetaTagProps {
  title: string
  description: string
  slug?: string
  featureImageTitle?: string
  featureImageDescription?: string
  openGraphTitle?: string
  openGraphDescription?: string
  openGraphImage: string
  openGraphImageAlt: string
  openGraphImageType?: string
  openGraphImageWidth?: number
  openGraphImageHeight?: number
}

export interface LandingPageProps {
  place: string
  content: LandingPageContent
  popularDestinationMeta: MetaTagProps
  destinationsWidget: DestinationWidgetProps[]
}

export interface PopularDestinationsWidgetProps {
  destinationsWidget: DestinationWidgetProps[]
}
export interface SearchVehiclesQuery {
  start: string
  end: string
  category: string
  transmission: string
  seats: number
  fuel: string
  creditCardNeeded: boolean
  locationLng: string
  locationLat: string
  rLocationLng: string
  rLocationLat: string
  pickup: string
  dropoff?: string
  distance: number
}

export interface PreFilledMapPlacesType {
  title: string
  preFillLocationDescription: string
  preFillGooglePlaceId: string
  preFillLat: number
  preFillLong: number
}

export interface HomePageProps {
  destinationsWidget: DestinationWidgetProps[]
  prefilledDestinations: PreFilledMapPlacesType[]
  searchConfig: { type: string; location: string }
}

export interface WidgetPageProps {
  widgetMode: boolean
  destinationsWidget: DestinationWidgetProps[]
  prefilledDestinations: PreFilledMapPlacesType[]
  searchConfig: { type: string; location: string }
  cfHeaders: CFHeaders
}

interface ImageType {
  url: string
}
export interface DestinationWidgetProps {
  id: string
  title: string
  widgetImage: ImageType | null
  widgetAltText: string
  widgetAttribution: string
  widgetAttributionColor: string
}

export interface BlogPostProps {
  id: string
  slug: string
  preview_text: string
  title: string
  alttext: string
  category: string
  categories: { id: string; optionName: string }[]
  featured_image: ImageType
  featureImageTitle: string
  metaDescription?: string
  metaOpenGraphTitle?: string
  metaOpenGraphDescription?: string
  featureImageDescription: string
  content: textType
}

export interface category {
  optionName: string
  id: string
  category_options: category[]
}

export interface NavigationObject {
  optionName: string
  id?: string
}

export interface GoogleMapBounds {
  southwest: {
    lat: number
    lng: number
  }
  northeast: {
    lat: number
    lng: number
  }
}

export interface CFHeaders {
  cfConnectingIp: string
  cfIpCountry: string
}
