import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useTranslation } from 'next-i18next'
const FormSeats = ({ setSeats }) => {
    const { t, i18n } = useTranslation()
    const [numberofSeats, setNumberofSeats] = useState(t('Number of Seats'))

    const onChange = (obj) => {
        if (obj) {
            setSeats(obj?.value)
            setNumberofSeats(obj?.label)
        } else {
            setSeats('')
        }
    }

    useEffect(() => {
        setNumberofSeats(t('Number of Seats'))
    }, [i18n.language, t])

    const newSeats = []
    const minValue = 2
    const maxValue = 9

    for (let i = minValue; i <= maxValue; i++) {
        const seat = {
            value: i,
            label: i.toString(),
        }
        newSeats.push(seat)
    }

    return (
        <div className="categories_container">
            <Select
                value={numberofSeats}
                onChange={onChange}
                options={newSeats}
                className=""
                placeholder={numberofSeats}
            />
        </div>
    )
}

export default FormSeats
