import axios, { AxiosInstance } from 'axios'
import appLocalStorage from './utils/AppLocalStorage'

// Create an Axios instance, this needs to be called on the client side, in order to set the session id that is stored in the local storage
// That's why it should not be called on the server side, (e.g. in getServerSideProps)

let axiosInstance: AxiosInstance = null

export const createAxiosInstance = (backend_url: string, x_header_auth: string): AxiosInstance => {
    if (axiosInstance) {
        return axiosInstance
    }
    axiosInstance = axios.create({
        baseURL: backend_url,
    })

    // Add a request interceptor to include additional headers
    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers['X-Header-Auth'] = x_header_auth
            config.headers['Content-Type'] = 'application/json'
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    return axiosInstance
}

export function mkConfig(currentUrl: string) {
    const sessionInfo = appLocalStorage().getSessionInfo()
    const cfHeaders = appLocalStorage().getCFHeaders()
    const sessionId = sessionInfo.id
    const rentalId = sessionInfo.rentalId
    // console.debug('mkConfig', { cfHeaders, sessionId, rentalId, currentUrl })
    return {
        headers: {
            'X-Session-Id': sessionId,
            'CF-Connecting-IP': cfHeaders.cfConnectingIp,
            'CF-IpCountry': cfHeaders.cfIpCountry,
            'X-Rental-Id': rentalId,
            'X-Referer-Url': currentUrl,
        },
    }
}
