import React, { useCallback, useEffect, useState } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { addCategory } from '../../actions/searchActions'
import { createAxiosInstance, mkConfig } from '../../axiosInstance'
import useCurrentUrl from '../../hooks/useCurrentUrl'
import { useTranslation } from 'next-i18next'

const Categories = ({ vType: initialVType, addCategory }) => {
    const [category, setCategory] = useState(null)
    const [error, setError] = useState(null)
    const [vType] = useState(initialVType)
    const [options, setOptions] = useState([{ value: 'Loading...', label: 'Loading...' }]) // Ensure label is set
    const currentUrl = useCurrentUrl()
    const { t, i18n } = useTranslation()

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const getOptions = useCallback(
        async (vType) => {
            if (!process.env.NEXT_PUBLIC_X_HEADER_AUTH) throw new Error('X_HEADER_AUTH missing')
            const x_header_auth = process.env.NEXT_PUBLIC_X_HEADER_AUTH
            if (!process.env.NEXT_PUBLIC_BACKEND_URL) throw new Error('NEXT_PUBLIC_BACKEND_URL missing')
            const backend_url = process.env.NEXT_PUBLIC_BACKEND_URL

            const url = '/fe/v1/data/categories'

            try {
                const response = await createAxiosInstance(backend_url, x_header_auth).get(
                    url,
                    mkConfig(currentUrl)
                )

                const res = []

                response.data.forEach((obj) => {
                    if (obj.vType === vType) {
                        res.push({
                            id: obj.id,
                            value: capitalizeFirstLetter(obj.name),
                            label: t(capitalizeFirstLetter(obj.name)),
                            vType: obj.vType,
                        })
                    }

                    if (vType === 'ATV' && obj.name === 'Quad') {
                        res.push({
                            id: obj.id,
                            value: capitalizeFirstLetter(obj.name),
                            label: t(capitalizeFirstLetter(obj.name)),
                            rank: obj.rank,
                            vType: obj.vType,
                        })
                    }
                })

                res.sort((a, b) => a.rank - b.rank)

                if (res.length > 0) {
                    if (vType === 'Car') {
                        res.unshift({
                            id: 1,
                            value: 'All Cars',
                            label: t('All Cars'), // Translate "All Cars"
                            rank: 1000000000,
                            vType: 'Car',
                        })
                    } else if (vType !== 'ATV') {
                        res.unshift({
                            id: 1,
                            value: 'All Motos',
                            label: t('All Motos'), // Translate "All Motos"
                            rank: 1000000000,
                            vType: 'Moto',
                        })
                    }

                    setCategory(res[0])
                    initializeOptions(res[0])
                }

                setOptions(res)
                setError(null)
            } catch (error) {
                console.error('categories', error)
                setError('Error connecting to database')
            }
        },
        [t, currentUrl, vType]
    )

    const initializeOptions = (selectedCategory) => {
        const copy = { ...selectedCategory }
        if (copy.value === 'All Motos') {
            copy.value = 'Motos'
        }
        if (copy.value === 'All Cars') {
            copy.value = 'Cars'
        }
        if (copy.value === 'All Vehicles') {
            copy.value = 'All'
        }

        addCategory(copy.value)
    }

    const onChange = (value) => {
        if (value !== null) {
            setCategory(value)
            initializeOptions(value)
        } else {
            setCategory('')
            addCategory(null)
        }
    }

    useEffect(() => {
        getOptions(vType)
    }, [vType, getOptions, i18n.language])

    return (
        <>
            <div className="categories_container">
                <div>
                    <div>
                        <Select
                            value={category}
                            valueKey="id"
                            labelKey="label" // Ensure "label" is used here for display
                            onChange={onChange}
                            options={options}
                            className=""
                            aria-label={category?.label}
                            isDisabled={error !== null}
                            placeholder={error === null ? t('All Categories') : t('Error connecting to database')} // Translate placeholder
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addCategory: (payload) => dispatch(addCategory(payload)),
    }
}

export default connect(null, mapDispatchToProps)(Categories)
